// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/nikomatses/Documents/GitHub/primary360/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-jsx": () => import("/Users/nikomatses/Documents/GitHub/primary360/src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-single-jsx": () => import("/Users/nikomatses/Documents/GitHub/primary360/src/templates/single.jsx" /* webpackChunkName: "component---src-templates-single-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/nikomatses/Documents/GitHub/primary360/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/nikomatses/Documents/GitHub/primary360/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

